import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import Company from '../components/Pages/Company';

// hooks
import useTranslated from '../hooks/useTranslated';

const CompanyComponent = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);

    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            showOurCustomers={false}
            hasContainer={false}
            metatags={translatedData.strapiCompany.metatags}
            href="/company/"
        >
            <Company data={translatedData.strapiCompany} lng={rest.pageContext.langKey} />
        </Layout>
    );
};

CompanyComponent.propTypes = {
    data: PropTypes.shape({
        strapiCompany: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default CompanyComponent;

export const query = graphql`
    query CompanyDe {
        strapiCompany {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            contact {
                title_en
                title_de
                phoneNumber
                emailAddress
                description_en
                description_de
            }
            feature {
                id
                title_de
                title_en
                feature {
                    description_de
                    description_en
                    icon {
                        publicURL
                    }
                    id
                }
            }
            sections {
                id
                description_en
                description_de
                image_de {
                    publicURL
                }
                image_en {
                    publicURL
                }
                title_de
                title_en
            }
        }
    }
`;
